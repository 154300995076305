import { PlaylistClip } from 'src/app/core/types/playlist.types';

export const generateUniqueId = (prefix: string = 'spa') => {
  // Get the current timestamp
  const timestamp = new Date().getTime();
  // Combine the prefix, timestamp, and random number to create a unique ID
  const uniqueId = `${prefix}-${timestamp}`;

  return uniqueId;
};

export const buildSelectedEventName = (event: any): string => {
  // Initialize an array to hold the parts of the title.
  const titleParts: string[] = [];

  // Add player name if available.
  if (event?.playerInfo?.name) {
    titleParts.push(event.playerInfo.name);
  }

  // Add match teams if both home and away teams are available.
  if (
    event?.matchInfo?.homeTeamShortName &&
    event?.matchInfo?.awayTeamShortName
  ) {
    titleParts.push(
      `${event.matchInfo.homeTeamShortName} - ${event.matchInfo.awayTeamShortName}`
    );
  }

  // Add event type name if available.
  if (event?.eventTypeInfo?.name) {
    titleParts.push(event.eventTypeInfo.name);
  }

  // Add additional event text if available.
  if (event?.text) {
    titleParts.push(event.text);
  }

  // Join all parts with " / " separator and return the result.
  return titleParts.join(' / ');
};

export const clearStorageByPrefix = (
  storage: {
    length: any;
    key: (arg0: number) => any;
    removeItem: (arg0: any) => void;
  },
  prefix: any
) =>
  Array(storage.length)
    .fill(1)
    .map((v, i) => storage.key(i))
    .filter((key) => key.indexOf(prefix) === 0)
    .forEach((key) => storage.removeItem(key));

export const buildClipName = (clip: PlaylistClip): string => {
  // Initialize an array to hold the parts of the title.
  const titleParts: string[] = [];

  // Add player name if available.
  if (clip?.playerInfo?.playerName) {
    titleParts.push(clip.playerInfo.playerName);
  }

  // Add match abbreviation if available.
  if (clip?.matchAbbreviation) {
    titleParts.push(clip.matchAbbreviation);
  }

  // Add event type name if available.
  if (clip?.eventTypeName) {
    titleParts.push(clip.eventTypeName);
  }

  // Add additional event text if available.
  if (clip?.text) {
    titleParts.push(clip.text);
  }

  // Join all parts with " / " separator and return the result.
  return titleParts.join(' / ');
};

export const getInitials = (fullName: string): string => {
  if (!fullName) return ''; // Return an empty string if input is empty or undefined

  // Split the full name by spaces and filter out any empty strings
  const nameParts = fullName.split(' ').filter(Boolean);

  // Map over each part, take the first letter, and join them together in uppercase
  const initials = nameParts.map((name) => name[0].toUpperCase()).join('');

  return initials;
}
