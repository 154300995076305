import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { DialogConfig } from '../../../dialog/models/dialog-config.model';
import { ConfirmDialogDataConfig } from '../../models/confirm-dialog.models';
import { DialogRef } from '../../../dialog/models/dialog-ref.model';
import { ButtonColor } from '@sportiananalysis/ui';

@Component({
  selector: 'spaui-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  private readonly acceptFn!: (payload: any) => void;
  private readonly cancelFn!: () => void;

  header!: string;
  message!: string | undefined;
  description!: string | undefined;
  acceptButtonText!: string;
  cancelButtonText!: string;
  acceptButtonColor: ButtonColor;
  cancelButtonColor: ButtonColor;
  showCancelButton: boolean;
  payload: any;

  constructor(
    @Optional() private dialogConfig: DialogConfig<ConfirmDialogDataConfig>,
    private dialogRef: DialogRef
  ) {
    const {
      message,
      description,
      acceptButtonText,
      cancelButtonText,
      acceptButtonColor,
      cancelButtonColor,
      acceptFn,
      cancelFn,
      payload,
      showCancelButton,
    } = (this.dialogConfig?.data || {}) as ConfirmDialogDataConfig;
    this.message = message;
    this.description = description;
    this.acceptButtonText = acceptButtonText || 'Accept';
    this.acceptButtonColor = acceptButtonColor || 'primary';
    this.cancelButtonText = cancelButtonText || 'Cancel';
    this.cancelButtonColor = cancelButtonColor || 'outline';
    this.showCancelButton = showCancelButton ?? true;
    this.acceptFn = acceptFn;
    this.cancelFn = cancelFn;
    this.payload = payload;
  }

  private _checkFn(fn: (payload?: any) => void, payload?: any) {
    if (fn) {
      fn(payload);
    }
  }

  accept() {
    this._checkFn(this.acceptFn, this.payload);
    this.dialogRef.close(this.payload || true);
  }

  cancel() {
    this._checkFn(this.cancelFn);
    this.dialogRef.close(false);
  }
}
