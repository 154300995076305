<div class="spaui-confirm-dialog">
  <div class="spaui-confirm-dialog__content">
    <div class="spaui-confirm-dialog__message">{{ message }}</div>
    <div
      class="spaui-confirm-dialog__description"
      [innerHTML]="description"
    ></div>
  </div>
  <div class="spaui-confirm-dialog__footer">
    @if (showCancelButton) {
    <spaui-button
      class="spaui-confirm-dialog__footer-button spaui-confirm-dialog__footer-button--cancel"
      mode="solid"
      [text]="cancelButtonText"
      [color]="cancelButtonColor"
      (click)="cancel()"
    ></spaui-button>
    }
    <spaui-button
      class="spaui-confirm-dialog__footer-button spaui-confirm-dialog__footer-button--accept"
      [text]="acceptButtonText"
      [color]="acceptButtonColor"
      (click)="accept()"
    ></spaui-button>
  </div>
</div>
