import { createAction, props } from '@ngrx/store';
import { Playlist, PlaylistClip } from 'src/app/core/types/playlist.types';

export const getPlaylists = createAction('[Playlist] Get playlists');
export const loadPlaylistsSuccess = createAction(
  '[Playlist] Get playlist success',
  props<{ data: Playlist[] }>()
);
export const loadPlaylistsFailure = createAction(
  '[Playlist] Get playlist failure',
  props<{ error: any }>()
);
export const selectPlaylist = createAction(
  '[Playlist] Select playlist',
  props<{ playlist: Playlist }>()
);
export const selectClip = createAction(
  '[Playlist] Select clip',
  props<{ clip: PlaylistClip }>()
);
export const getUserPlaylists = createAction(
  '[Playlist] Get playlists by user',
  props<{ userId: string; reload: boolean }>()
);
export const loadUserPlaylistsSuccess = createAction(
  '[Playlist] Get user playlists success',
  props<{ data: any[] }>()
);
export const loadUserPlaylistsFailure = createAction(
  '[Playlist] Get user playlist failure',
  props<{ error: any }>()
);
export const addUserPlaylist = createAction(
  '[Playlist] Add user playlist',
  props<{ playlist: any }>()
);

export const favouriteAddedPlaylist = createAction(
  '[Playlist] Favourite Clip Added',
  props<{ playlistId: string }>()
);

export const deleteClip = createAction(
  '[Playlist] Delete clips',
  props<{ clipIds: string[] }>() // Accepts an array of IDs
);

export const deleteClipSuccess = createAction(
  '[Playlist] Delete clips success',
  props<{ clipIds: string[] }>() // Returns the array of deleted IDs
);

export const deleteClipFailure = createAction(
  '[Playlist] Delete clips failure',
  props<{ error: any }>()
);

export const updateDownloadPanelState = createAction(
  '[Playlist] Change download panel state',
  props<{ isOpen: boolean }>()
);

export const updateDownloadClips = createAction(
  '[Playlist] Update selected clips for download',
  props<{ clips: PlaylistClip[] }>()
);
